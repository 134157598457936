var catalog = {
	el: {
		doc: $(document),
		aside: $('aside'),
		filtersBar: $('.filters-bar'),
		table: $('.table'),
		productDetail: $('.product-detail'),
		productOrder: $('.product-order'),
		productOrderHeader: $('.product-order-header'),
		addFavorite: $('.add-favorite'),
		btnNext: $('a.next'),
		btnPrev: $('a.prev'),
		stepOne: $('.step-one'),
		stepTwo: $('.step-two'),
		stepThree: $('.step-three'),
		productsBlock: $('.products-block'),
		highlights: $('.highlights'),
		checkboxes: $('.checkboxes:not(.digital-product .checkboxes)'),
		checkboxAddress: $('#correct-data'),
		checkboxesCashback: $('.digital-product .checkboxes'),
		checkboxIban: $('#correct-data-cashback'),
		stepTwoDigital: $('.digital-product'),
		editInfo: $('.js-edit-info'),
		email: $('.email-value'),
		iban: $('.iban-value'),
		digitalInput: $('.digital-product .digital-input'),
		ibanInput: $('.digital-product .iban-input'),
		resendCode: $('.resend-code-button')
	},
	events: function () {

		var self = this;

		this.el.filtersBar.find('a').on('click', function () {
			$(this).parent().siblings().removeClass('selected');
			$(this).parent().addClass('selected');
			getList.events(this)
		});

		this.el.doc.on('change', '.filters-bar-responsive', function () {
			getList.events($(this).find(':selected'))
		})

		this.el.doc.on('click', '.prizes .item', function () {
			getDetail.events(this)

		});

		this.el.doc.on('click', '.product-list .item', function () {
			getDetail.events(this);
			self.el.table.hide();
			self.el.productDetail.show()
		});

		this.el.doc.on('click', '.change-points', function () {
			self.el.productDetail.hide();
			self.el.productOrder.show();
			self.el.stepOne.show();
			self.el.stepTwo.add(self.el.stepThree).hide();
			self.el.productOrderHeader.find('li').siblings().removeClass('selected');
			self.el.productOrderHeader.find('li').first().addClass('selected');
			self.el.stepTwoDigital.find('input').hide();
			self.el.stepTwoDigital.find('.digital-notification').hide();
			self.el.stepThree.find('.confirmation-code').hide();
		});

		this.el.productsBlock.find('.product').on('click', function () {
			getDetail.events(this)
			self.el.table.add(self.el.highlights).hide();
			self.el.productDetail.show()
		})

		self.el.editInfo.on('click', this.editEmailIBANInfo)
	},

	navigation: function () {
		var self = this;



		this.el.stepTwo.add(this.el.stepThree).hide();

		this.el.btnNext.on('click', function () {
			self.el.btnPrev.show();
			self.el.productOrderHeader.find('li').siblings().removeClass('selected');

			if (self.el.stepThree.is(':visible')) {
				if(self.el.stepThree.find('.confirmation-code').is(':visible')) {
					if(self.el.stepThree.find('.confirmation-code-input').val().length > 0) {
						self.sendCode(self.el.stepThree.find('.confirmation-code-input').val())
					} else {
						alert(self.el.checkboxAddress.data('msg'))
					}
				} else {
					if($('.confirmation-cashback').is(':visible')) {
						self.cashbackOrder(this)
					} else {
						self.finishOrder(this)
					}
				}
			}



			if (self.el.stepTwo.is(':visible')) {
				if(self.el.checkboxesCashback.is(':visible')) {
					if(!self.el.checkboxIban.prop('checked')) {
						if($('.cashback-product input').is(':visible')) {
							alert(self.el.checkboxIban.data('msg-cashback'));
						} else {
							alert(self.el.checkboxAddress.data('msg-cashback'));
						}
					} else {
						if (self.el.digitalInput.hasClass('is-visible')) {
							if(self.el.ibanInput.hasClass('is-visible') && self.el.ibanInput.val().length < 25) {
								self.el.ibanInput.data('invalid');
							} else if(self.el.digitalInput.val().length > 0 && self.el.digitalInput.val().length > 0) {
								self.sendEmail(self.el.digitalInput.val(), self.el.ibanInput.val());
							}
						} else if(self.el.ibanInput.hasClass('is-visible') && self.el.ibanInput.val().length < 25) {
								alert(self.el.ibanInput.data('invalid'));
						} else {
							self.el.stepTwo.hide()
							self.el.stepThree.show()
							self.el.productOrderHeader.find('li').last().addClass('selected')
							self.el.btnNext.text('FINALIZAR')

							if(self.el.stepTwo.find($('.digital-product .iban-input')).hasClass('is-visible')) {
								console.log('yoyo');
								self.el.stepThree.find('.confirmation-cashback > p > span').text(self.el.stepTwo.find($('.digital-product .iban-input')).val())
								self.el.iban.text(self.el.stepTwo.find($('.digital-product .iban-input')).val())
							}
						}

						// console.log('asdasd');

					}
				} else if(self.el.checkboxes.is(':visible')) {
					if (!self.el.checkboxAddress.prop('checked')) {
						if(self.el.stepTwoDigital.is(':visible')) {
							if($('.cashback-product').is(':visible')) {
								alert(self.el.checkboxAddress.data('msg-cashback'));
							} else {
								alert(self.el.checkboxAddress.data('msg-email'))
							}
						} else {
							alert(self.el.checkboxAddress.data('msg'))
						}
						self.el.productOrderHeader.find('li').first().next().addClass('selected')
					} else {
						self.el.stepTwo.hide()
						self.el.stepThree.show()
						self.el.productOrderHeader.find('li').last().addClass('selected')
						self.el.btnNext.text('FINALIZAR')
					}
				} else {
					if (self.el.digitalInput.hasClass('is-visible')) {
						if(self.el.digitalInput.val().length > 0) {

							if(self.el.digitalInput.is(':visible')) {
								if($('.cashback-product').is(':visible')) {
									if(self.el.ibanInput.hasClass('is-visible')) {
										if(self.el.ibanInput.val().length > 0) {
											self.sendEmail(self.el.digitalInput.val(), self.el.ibanInput.val());
										} else {
											alert(self.el.ibanInput.data('msg'))
										}
									} else {
										self.sendEmail(self.el.digitalInput.val(), self.el.iban.text());
									}
								} else {
									self.sendEmail(self.el.digitalInput.val());
								}
							} else {

								self.el.stepTwo.hide()
								self.el.stepThree.show()
								self.el.productOrderHeader.find('li').last().addClass('selected')
								self.el.btnNext.text('FINALIZAR')
								self.el.stepThree.find('.confirmation-digital > p span').text(self.el.digitalInput.val())
								self.el.stepThree.find('.confirmation-code').show();
							}

							self.el.resendCode.on('click', function () {
								self.sendEmail(self.el.digitalInput.val());
							})
						} else {
							alert(self.el.digitalInput.data('msg'))
						}
					} else {
						self.el.stepTwo.hide()
						self.el.stepThree.show()
						self.el.productOrderHeader.find('li').last().addClass('selected')
						self.el.btnNext.text('FINALIZAR')
					}
				}
			}

			if (self.el.stepOne.is(':visible')) {
				self.el.stepTwo.show()
				self.el.stepOne.hide()
				self.el.productOrderHeader.find('li').first().next().addClass('selected')
				self.el.checkboxesCashback.hide();

				if(self.el.stepTwo.find('.digital-product').is(':visible')) {
					self.el.stepTwo.find('.digital-product p').show();
					if($('.email-value').text().length < 1) {
						$('.email-value').siblings('.js-edit-info').hide();
						self.el.email.hide();
						self.el.stepTwoDigital.find('.digital-input').show();
						self.el.stepTwoDigital.find('.digital-input').addClass('is-visible');
						self.el.stepTwoDigital.find('.digital-notification').show();
						self.el.checkboxes.hide();
					} else {
						self.el.stepTwoDigital.find('.digital-notification').hide();
					}

					if($('.cashback-product').is(':visible')) {
						if($('.iban-value').text().length < 1) {
							$('.iban-value').siblings('.js-edit-info').hide();
							self.el.iban.hide();
							self.el.stepTwoDigital.find('.iban-input').show();
							self.el.stepTwoDigital.find('.iban-input').addClass('is-visible');
							self.el.stepTwoDigital.find('.digital-notification').show();
							self.el.checkboxes.hide();
							self.el.checkboxesCashback.show();
						} else {
							self.el.stepTwoDigital.find('.digital-notification').hide();
						}
					}
				}
			}
		})
		this.el.btnPrev.on('click', function () {
			self.el.productOrderHeader.find('li').siblings().removeClass('selected');
			self.el.checkboxAddress.prop('checked', false)

			if (self.el.stepTwo.is(':visible')) {
				$(this).hide()
				self.el.stepOne.show()
				self.el.stepTwo.hide()
				self.el.productOrderHeader.find('li').first().addClass('selected')

				if(self.el.stepTwo.find('.input').is(':visible')) {
					self.el.editEmail.show();
					self.el.email.show();
					self.el.stepTwoDigital.find('input').hide();
					self.el.stepTwoDigital.find('input').removeClass('is-visible');
					self.el.stepTwoDigital.find('.digital-notification').hide();
					self.el.checkboxes.show();
				}
			}
			if (self.el.stepThree.is(':visible')) {
				self.el.stepTwo.show()
				self.el.stepThree.hide()
				self.el.productOrderHeader.find('li').first().next().addClass('selected')
				self.el.btnNext.text('AVANÇAR')

				if(self.el.stepThree.find('.confirmation-code').is(':visible')) {
					self.el.editEmail.show();
					self.el.email.show();
					self.el.stepTwoDigital.find('input').hide();
					self.el.stepTwoDigital.find('input').removeClass('is-visible');
					self.el.stepTwoDigital.find('.digital-notification').hide();
					self.el.checkboxes.show();
				}
			}

		})
	},

	finishOrder: function (e) {
		var self = this;
		var newData = '';

		if($('.quantity').is(':visible')) {
			newData = JSON.stringify({
				'id': $(e).data('id'),
				'quantity': $('.quantity-picker span').text(),
			})
		} else {
			newData = JSON.stringify({
				'id': $(e).data('id'),
				'quantity': $('.quantity-picker span').text(),
			})
		}

		$.ajax({
			url: self.el.aside.data('order-endpoint') + $(e).data('id'),
			type: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: newData,
			beforeSend: function () {
				$(e).text('A CARREGAR')
			},

		}).done(function (data) {
			self.el.productOrder.find('.message-success').show();
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information')).hide();


		}).fail(function (jqXHR) {
			console.error(jqXHR.responseText)
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information').add(self.el.productOrderHeader)).hide();
			(self.el.productOrder).append('<p class="order-error">Ocorreu um erro tente novamente</p>')
			setTimeout(function () {
				$('.order-error').remove();
				self.el.productOrder.find('.product-order-btns').add(self.el.stepOne).add(self.el.productOrder.find('.information').add(self.el.productOrderHeader)).show();
				self.el.btnPrev.hide();
				$(e).text('AVANÇAR')
				self.el.productOrderHeader.find('li').first().addClass('selected')
			},
			3000)
		})
	},

	cashbackOrder: function (e) {
		var self = this;

		$.ajax({
			url: self.el.aside.data('cashback-order-endpoint'),
			type: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify({
				'id': $(e).data('id'),
				'quantity': $('.quantity-picker span').text(),
				'iban': $('.confirmation-cashback p span').text()
			}),
			beforeSend: function () {
				$(e).text('A CARREGAR')
			},

		}).done(function (data) {
			self.el.productOrder.find('.message-success').show();
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information')).hide();


		}).fail(function (jqXHR) {
			console.error(jqXHR.responseText)
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information').add(self.el.productOrderHeader)).hide();
			(self.el.productOrder).append('<p class="order-error">Ocorreu um erro tente novamente</p>')

			setTimeout(function () {
				$('.order-error').remove();
				self.el.productOrder.find('.product-order-btns').add(self.el.stepOne).add(self.el.productOrder.find('.information').add(self.el.productOrderHeader)).show();
				self.el.btnPrev.hide();
				$(e).text('AVANÇAR')
				self.el.productOrderHeader.find('li').first().addClass('selected')
			},
			3000)
		})
	},

	editEmailIBANInfo: function (e) {
		e.stopPropagation();

		var self = catalog,
			target = $(e.currentTarget),
			targetParent = target.closest('div'),
			siblingValue = targetParent.find('.value');

		targetParent.find('input').val(siblingValue.text());
		target.hide();
		siblingValue.hide();
		targetParent.find('input').show();
		targetParent.find('input').addClass('is-visible');

		self.el.checkboxes.hide();
		self.el.stepTwoDigital.find('.digital-notification').show();

		if(targetParent.hasClass('cashback-product')) {
			self.el.checkboxesCashback.show();
		}
	},

	sendEmail: function (email, iban) {
		var self = this,
			newData = '';

		console.log('iban');

		if($('.cashback-product').is(':visible')) {
			newData = JSON.stringify({
				'email': email,
				'iban': iban
			})
		} else {
			newData = JSON.stringify({
				'email': email,
			})
		}

		$.ajax({
			url: self.el.aside.data('send-confirmation-code-endpoint') + email,
			type: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: newData
			// beforeSend: function () {
			// 	$(e).text('A CARREGAR')
			// },

		}).done(function () {
			if(self.el.stepTwo.is(':visible')) {
				self.el.stepTwo.hide()
				self.el.stepThree.show()
				self.el.productOrderHeader.find('li').last().addClass('selected')
				self.el.btnNext.text('FINALIZAR')
				self.el.stepThree.find('.confirmation-digital > p span').text(email)
				self.el.stepThree.find('.confirmation-code').show();
				if($('.confirmation-cashback').is(':visible')) {
					self.el.stepThree.find('.confirmation-cashback span').text(iban)
				}
			}
		}).fail(function (jqXHR) {
			alert(self.el.digitalInput.data('invalid'))
		})
	},

	sendCode: function (code) {
		var self = this;

		$.ajax({
			url: self.el.aside.data('validate-code-endpoint') + code,
			type: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify({
				'code': code,
			})
			// beforeSend: function () {
			// 	$(e).text('A CARREGAR')
			// },

		}).done(function (data) {
			self.finishOrder(self.el.btnNext)
			self.el.productOrder.find('.message-success').show();
			self.el.productOrder.find('.product-order-btns').add(self.el.stepThree).add(self.el.productOrder.find('.information')).hide();

		}).fail(function (jqXHR) {
			alert(self.el.stepThree.find('.confirmation-code-input').data('invalid'))
		})
	},

	init: function () {
		this.events();
		this.navigation();
	}
}
catalog.init();
