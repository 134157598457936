getDetail = {
	el: {
		aside: $('aside'),
		productDetail: $('.product-detail'),
		productOrder: $('.product-order'),
		highlights: $('.highlights'),
		table: $('.table'),
		filtersBar: $('.filters-bar'),
		filtersBarResponsive: $('.filters-bar-responsive'),
		stepTwoPhysical: $('.physical-product'),
		stepTwoDigital: $('.digital-product'),
		stepThreePhysical: $('.confirmation-physical'),
		stepThreeDigital: $('.confirmation-digital'),
		stepTwo: $('.step-two'),
		messageSuccess: $('.message-success')
	},

	events: function (e) {

		var self = this;

		self.el.filtersBar.add(self.el.filtersBarResponsive).hide();

		$.ajax({
			type: 'GET',
			url: self.el.aside.data('products-details-endpoint') + (window.location.search ? window.location.href.substring(window.location.href.lastIndexOf('=') + 1) : $(e).data('id')),
			// url: 'http://vidradosemganhar.clientes.dev.wycreative.com/api/catalog/GetProductDetail/36225',
			// url: '../../assets/data/2805.json',
			dataType: 'json',
			contentType: 'application/json',
			beforeSend: function () {
				self.el.productDetail.append('<div class="loading">A carregar</div>')
			},
		}).done(function (data) {
			$('.loading').remove();
			self.el.productDetail.html('')
			self.el.productDetail.append(
				'<div class="product-detail-header">' +
				'<h2>' + data.name + '</h2>' +
				'<div class="favorites-widget">' +
				'<a class="js-favorite" href="javascript:;">Favoritos</a>' +
				'</div>' +
				'</div>' +
				'<div class="product-detail-container">' +
				'<div class="product-detail-image">' +
				'<img src="' + data.image + '" alt=""/>' +
				'</div>' +
				'<div class="product-detail-description">' +
				'<div>' +
				'<p>' + data.contents + '</p>' +
				'</div>' +
				'</div>' +
				'<div class="product-detail-data">' +
				//'<p class="data-reference"> Ref: ' + data.ref + '</p>' +
				'<div class="data-wrapper">' +
				'<p class="points"><span>' + data.points + '</span> pts</p>' +
				'<p class="available-points">Pontos Disponíveis: ' +
				'<span>' + data.availablePoints + '</span></p>' +
				//'<p class="request-rate">Mais pedido: ' +
				//'<span>' + data.requestRate + '</span></p>' +
				(data.availablePoints >= data.points ?
				'<a class="change-points" href="javascript:;">TROCAR<span>PONTOS</span></a>' : '') +
				'<a class="add-favorite js-add-favorite ' + (data.isFavourite === true ? "favorite" : '') + '" data-id="' + data.id + '" href="javascript:;">' + (data.isFavourite === true ? 'REMOVER' : 'MARCAR') + ' FAVORITO </a>' +
				'</div>' +
				'</div>'
			);

			self.el.productOrder.find('img').attr('src', data.image)
			self.el.productOrder.find('.js-name').text(data.name)
			self.el.productOrder.find('.points').html('<strong>' + data.points + '</strong> pts')
			self.el.productOrder.find('a.next').attr('data-id', data.id)

			if(data.isDigital) {
				self.el.stepTwoPhysical.hide()
				self.el.stepTwoDigital.show()
				self.el.stepThreePhysical.hide()
				self.el.stepThreeDigital.show()
				self.el.stepTwo.find('.address-content div:first-of-type p:last-of-type').hide()
				self.el.stepTwo.find('.label-physical').hide()
				self.el.stepTwo.find('.label-digital').show()
				self.el.messageSuccess.find('.message-success-physical').hide()
				self.el.messageSuccess.find('.message-success-digital').show()
				self.el.productOrder.find('.quantity').show();
				self.el.productOrder.find('.quantity-points span').text(data.points)
				self.el.productOrder.find('.quantity-picker button').on('click', function(e) {
					getDetail.productQuantity(e, data.availablePoints, data.points, data.stock)
				})
				data.availablePoints < data.points * 2 ? self.el.productOrder.find('.quantity-picker .quantity-plus').attr('disabled', '') : 0;
			} else {
				self.el.stepTwoPhysical.show()
				self.el.stepTwoDigital.hide()
				self.el.stepThreePhysical.show()
				self.el.stepThreeDigital.hide()
				self.el.stepTwo.find('.address-content div:first-of-type p:last-of-type').show()
				self.el.stepTwo.find('.label-digital').hide()
				self.el.stepTwo.find('.label-physical').show()
				self.el.messageSuccess.find('.message-success-physical').show()
				self.el.messageSuccess.find('.message-success-digital').hide()
				self.el.productOrder.find('.quantity').hide();
			}

			if(data.isCashback) {
				$('.cashback-product').show();
				$('.digital-notification').text($('.digital-notification').data('cashback'));
			} else {
				$('.cashback-product').hide();
				$('.confirmation-cashback').hide();
			}
		})
	},

	onLoadDetail: function () {

		if (window.location.search) {
			this.events()
			this.el.table.add(this.el.highlights).hide();
			this.el.productDetail.show()
			window.history.replaceState(null, null, window.location.pathname);

		}
	},

	productQuantity: function(e, availablePoints, points, stock) {
		var target = $(e.target);
		var parentContainer = target.closest('.quantity-picker');
		// var availablePoints = 400;
		// var points = 110;
		var productCount = parentContainer.find('span');
		var productCountNumber = parseInt(productCount.text());
		var totalValue = getDetail.el.productOrder.find('.quantity-points span');


		if (target.hasClass('quantity-plus')) {
			if (availablePoints <= points * (productCountNumber + 1) || productCountNumber + 1 >= stock) {
				target.attr('disabled', '');
			}

			productCountNumber++
			productCount.text(productCountNumber);
			totalValue.text(points * productCountNumber);

			if(productCountNumber > 1) {
				parentContainer.find('.quantity-minus').removeAttr('disabled');
			}
		}

		if (target.hasClass('quantity-minus')) {
			parentContainer.find('.quantity-plus').removeAttr('disabled');
			productCountNumber--
			productCount.text(productCountNumber);
			totalValue.text(points * productCountNumber);

			if(productCountNumber === 1) {
				target.attr('disabled', '');
			}
		}

	},

	init: function () {
		this.onLoadDetail()
	}
}

getDetail.init();
