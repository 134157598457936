var userDataForm = {

	events: function () {
		var userForm = $('.user-data-form');
		var form = userForm.find('form');
		var submitBtn = form.find('.submit-btn');
		var requiredInputs = form.find('input[required]');
		
		if (userForm.length < 1) {
			return;
		}

		submitBtn.on('click', function(e) {
			e.preventDefault();
			userDataForm.validateForm(form);
			form.find('.submit-btn').attr('disabled', '');
		})

		// requiredInputs.on('input change', function() {
		// 	userDataForm.isFormComplete(form)
		// })
	},

	// isFormComplete: function(form) {
	// 	var $form = form;
	// 	var requiredInput = $form.find('input[required');
	// 	var formComplete = true;
	// 	var submitBtn = $form.find('.submit-btn')

	// 	requiredInput.each(function () {
	// 		if ($(this).is(":checkbox")) {
	// 			if (!$(this).is(":checked")) {
	// 				formComplete = false;
	// 			}
	// 		} else {
	// 			if (!$(this).val().trim()) {
	// 				formComplete = false;
	// 			}
	// 		}
	// 	})

	// 	submitBtn.prop("disabled", !formComplete);
	// },

	validateForm: function(form) {
		var $form = form;
		var requiredInput = $form.find('input[required]');
		// var errorText = $form.find('.error-text');
		var errorContainer = $form.find('.error-container');
		var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		var inputContainers = $form.find('.container');
		var $submitBtn = $form.find('.submit-btn');

		inputContainers.each(function() {
			$(this).removeClass('w-error');
		})

		errorContainer.empty();

		requiredInput.each(function() {
			var container = $(this).closest('.container');
			var errorText = $('<p class="error-text"></p>');
			
			if (this.type === 'text') {
				if (!this.value) {
					errorText.append(container.data('error-empty'));
					errorContainer.append(errorText);
					container.addClass('w-error');
				} else if (this.name === 'nome_contacto' && this.value.match(/\d+/) !== null) {
					errorText.append(container.data('error-format'));
					errorContainer.append(errorText);
					container.addClass('w-error');
				} else if (this.name === 'telemovel' && !Number(this.value.replace(/\s/g, ''))) {
					errorText.append(container.data('error-format'));
					errorContainer.append(errorText);
					container.addClass('w-error');
				}
			}

			if (this.type === 'email') {
				if (!this.value) {
					errorText.text(container.data('error-empty'));
					errorContainer.append(errorText);
					container.addClass('w-error');
				} else if (!emailPattern.test(this.value)) {
					errorText.text(container.data('error-format'));
					errorContainer.append(errorText);
					container.addClass('w-error');
				}
			}

			if (this.type === 'checkbox') {
				if($(this).is(':checked') === false) {
					errorText.text(container.data('error-empty'));
					errorContainer.append(errorText);
					container.addClass('w-error');
				}
			}
		})

		if (form.find('.w-error').length > 0) {
			setTimeout(function() {
				$submitBtn.removeAttr('disabled');
			}, 0);
		} else {
			userDataForm.sendForm(form);
		}
	},

	sendForm: function(form) {
		var formInputs = form.find('input');
		var formData = {};
		
		formInputs.each(function() {
			var $this = $(this);
			var name = $(this).attr('name');
			var value = $(this).val();

			if (name.length > 0) {
				if ($this.is(":checkbox")) {
					if ($this.is(":checked")) {
						formData[name] = true;
					} else {
						formData[name] = false;
					}
				} else {
					formData[name] = value;
				}
			}
		})

		$.ajax({
			type: "POST",
			url: form.attr('action'),
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(formData),
			success: function(response) {
				form.find('.response').addClass('success');
			},
			error: function(xhr, status, error) {
				form.find('.response').addClass('error');
			},
			complete: function() {
				setTimeout(function() {
					form.find('.response').removeClass('success error');
					form.find('.submit-btn').removeAttr('disabled');
				}, 5000);
			},
		})
	},

	init: function () {
		this.events();
	},

}
userDataForm.init()